import { FC } from "react";
import { generatePath, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  isChatPath,
  useCountUnreadChats,
  useGetProfileMenuRoutes
} from "../../../utils/navbar";
import { TMenuRoute } from "../../../routes";

import {
  StickyNavbarContainer,
  StickyNavbarItem,
  StyledStickyNavbar,
  NavbarInfoContainer
} from "./styles";

export const MobileStickyNavbar: FC = () => {
  const profileNavbarRoutes = useGetProfileMenuRoutes();
  const { t, i18n } = useTranslation("header");
  const stickyNavbarRoutes: TMenuRoute[] = profileNavbarRoutes.filter(
    (item) => item.keyLocalize !== "blog"
  );
  const unreadCount = useCountUnreadChats();

  return (
    <StyledStickyNavbar>
      <StickyNavbarContainer $itemsAmount={stickyNavbarRoutes.length}>
        {stickyNavbarRoutes.map((item) => (
          <StickyNavbarItem key={`mobileStickyNavbar${item.keyLocalize}`}>
            <NavLink
              to={generatePath(item.path, {
                lng: i18n.language,
                id: isChatPath(item.path) ? "all" : ""
              })}
            >
              {item.icon}
              <span>
                {t(`subMenu.${item.keyLocalize}`)}
                {!!unreadCount && item.keyLocalize === "chats" ? (
                  <NavbarInfoContainer>
                    <span className="navbarInfo">{unreadCount}</span>
                  </NavbarInfoContainer>
                ) : null}
                {/*{item.keyLocalize === "saved" && searches.length > 0 ? (*/}
                {/*  <NavbarInfoContainer*/}
                {/*    className="countSearchesInfo"*/}
                {/*    $isShortNavbarItemName={i18n.language !== "nl"}*/}
                {/*  >*/}
                {/*    <span className="navbarInfo countSearches">{searches.length + 1}</span>*/}
                {/*  </NavbarInfoContainer>*/}
                {/*) : null}*/}
              </span>
            </NavLink>
          </StickyNavbarItem>
        ))}
      </StickyNavbarContainer>
    </StyledStickyNavbar>
  );
};
